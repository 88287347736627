import React from 'react';
import styled, { ThemeProvider } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 1170px;
  padding: 50px 20px 0 20px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 40px 20px 0 20px;
  }

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }
`;

const HomePage = () => {

  return (
    <Wrapper>
      Renni Deco
    </Wrapper>
  );
};

export default HomePage;


